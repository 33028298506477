import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
import Icon from "./Icon";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "icon"
    }}>{`Icon`}</h1>
    <Props of={Icon} mdxType="Props" />
    <h2 {...{
      "id": "icons-list"
    }}>{`Icons list`}</h2>
    <p>{`In addition to our own icons, the following icon families can also be used: `}<a parentName="p" {...{
        "href": "https://remixicon.com/"
      }}>{`Remix Icons`}</a>{` and `}<a parentName="p" {...{
        "href": "https://erikflowers.github.io/weather-icons/"
      }}>{`Weather Icons`}</a>{`.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Custom Icons`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Name`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="checkmark" size="46px" altText="This is a checkmark icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`checkmark`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="close" size="46px" altText="This is a close icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`close`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="chevron" size="46px" altText="This is a chevron icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`chevron`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="bank" size="46px" altText="This is a bank icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`bank`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="carInsurance" size="46px" altText="This is a car insurance icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`carInsurance`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="car" size="46px" altText="This is a car icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`car`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="car2" size="46px" altText="This is a car icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`car2`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="coins" size="46px" altText="This is a coins icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`coins`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="female" size="46px" altText="This is a female icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`female`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="financialPlanning" size="46px" altText="This is a financial planning icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`financialPlanning`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="find" size="46px" altText="This is a find icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`find`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="graduationCap" size="46px" altText="This is a graduation cap icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`graduationCap`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="male" size="46px" altText="This is a male icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`male`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="money" size="46px" altText="This is a money icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`money`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="rockingChair" size="46px" altText="This is a rocking chair icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`rockingChair`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="veteran" size="46px" altText="This is a veteran icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`veteran`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="coffee" size="46px" altText="This is coffee icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`coffee (The icon is completely white)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="display2" size="46px" altText="This is display2 icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`display2 (The icon is completely white)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="coins2" size="46px" altText="This is a coins icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`coins2`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="coffeeColor" size="46px" altText="This is coffee icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`coffeeColor`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="computer" size="46px" altText="This is coffee icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`computer`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="uninsured" size="46px" altText="This is uninsured icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`uninsured`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="bond" size="46px" altText="This is the MoneyGeek Logo" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`bond`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="umbrella" size="46px" altText="This is umbrella icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`umbrella`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="rain" size="46px" altText="This is rain icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`rain`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="insurance2" size="46px" altText="This is insurance2 icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`insurance2`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="handcuffs2" size="46px" altText="This is handcuffs2 icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`handcuffs2`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="accident2" size="46px" altText="This is accident2 icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`accident2`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="mglogo" size="46px" altText="This is the MoneyGeek Logo" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`mglogo`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="eyeglasses" size="46px" altText="This is accident2 icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`eyeglasses`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="pieChart" size="46px" altText="This is a pie chart icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`pieChart`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="grid" size="46px" altText="This is a grid icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`grid`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="barChart" size="46px" altText="This is a bar chart icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`barChart`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="swipe" size="46px" altText="This is a swipe icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`swipe`}</td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Home Icons`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Name`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="airConditioner" size="46px" altText="This is an air conditioner icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`airConditioner`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="blender" size="46px" altText="This is a blender icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`blender`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="bookshelves" size="46px" altText="This is a bookshelves icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`bookshelves`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="building" size="46px" altText="This is a building icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`building`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="carpet" size="46px" altText="This is a carpet icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`carpet`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="cleanClothes" size="46px" altText="This is a clean clothes icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`cleanClothes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="creditCard" size="46px" altText="This is a credit card icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`creditCard`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="dish" size="46px" altText="This is a dish icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`dish`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="ecoHouse" size="46px" altText="This is an eco house icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`ecoHouse`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="engagementRing" size="46px" altText="This is an engagement ring icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`engagementRing`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="fryingPan" size="46px" altText="This is a frying pan icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`fryingPan`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="garage" size="46px" altText="This is a garage icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`garage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="greenHouse" size="46px" altText="This is a green house icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`greenHouse`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="grill" size="46px" altText="This is a grill icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`grill`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="hammer" size="46px" altText="This is a hammer icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`hammer`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="homeInsurance" size="46px" altText="This is a home insurance icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`homeInsurance`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="house" size="46px" altText="This is a house icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`house`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="housePapers" size="46px" altText="This is a house papers icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`housePapers`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="house2" size="46px" altText="This is a house2 icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`house2`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="laptop" size="46px" altText="This is a laptop icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`laptop`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="mortgage" size="46px" altText="This is a mortgage icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`mortgage`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="necklace" size="46px" altText="This is a necklace icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`necklace`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="paintingRoller" size="46px" altText="This is a painting roller icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`paintingRoller`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="photoCamera" size="46px" altText="This is a photo camera icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`photoCamera`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="refrigerator" size="46px" altText="This is a refrigerator icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`refrigerator`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="rentPapers" size="46px" altText="This is a rent papers icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`rentPapers`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="smallCalculator" size="46px" altText="This is a small calculator icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`smallCalculator`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="smartphone" size="46px" altText="This is a smartphone icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`smartphone`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="sofa" size="46px" altText="This is a sofa icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`sofa`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="towels" size="46px" altText="This is a towels icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`towels`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="tvSet" size="46px" altText="This is a tv set icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`tvSet`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="weight" size="46px" altText="This is a weight icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`weight`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="wristwatch" size="46px" altText="This is a wristwatch icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`wristwatch`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="bag" size="46px" altText="This is a bag icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`bag`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><Icon name="other" size="46px" altText="This is a other icon" mdxType="Icon" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`other`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "default-basic-usage"
    }}>{`Default Basic Usage`}</h2>
    <Playground __position={1} __code={'<Icon name=\"bank\" size=\"46px\" altText=\"This is a bank icon\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Icon,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Icon name="bank" size="46px" altText="This is a bank icon" mdxType="Icon" />
    </Playground>
    <h2 {...{
      "id": "custom-fill-color-basic-usage"
    }}>{`Custom Fill Color Basic Usage`}</h2>
    <p>{`Available for `}<strong parentName="p">{`checkmark`}</strong>{`, `}<strong parentName="p">{`close`}</strong>{` and `}<strong parentName="p">{`chevron`}</strong>{` icons and the `}<strong parentName="p">{`Remix`}</strong>{` and `}<strong parentName="p">{`Weather`}</strong>{` Icons families.`}</p>
    <Playground __position={2} __code={'<Icon name=\"checkmark\" size=\"46px\" altText=\"Checkmark icon\" color=\"red\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Icon,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Icon name="checkmark" size="46px" altText="Checkmark icon" color="red" mdxType="Icon" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      